import React from "react";
import "./Currentwork.scss";
import video from "../../Assets/working/video.mp4";

const Presentwork = [
  {
    video: { video },
    title: "Project Title 1",
    unit: "small",

    location: "indore",
    proType: "Project Title",
    dropdate: "2017-22-2",
    description:
      "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,    facilisis a in justo.",
  },
  {
    video: { video },
    title: "Project Title 1",
    unit: "small",

    location: "indore",
    proType: "Project Title",
    dropdate: "2017-22-2",
    description:
      "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,    facilisis a in justo.",
  },
  {
    video: { video },

    Image:
      "http://ts1.mm.bing.net/th?id=OIP._wTe5BpSrBLVRSsG-SkNpQHaFj&pid=15.1",
    title: "Project Title 1",
    unit: "small",

    location: "indore",
    proType: "Project Title",
    dropdate: "2017-22-2",
    description:
      "Lorem ipsum dolor sit amet,  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,   facilisis a in justo.",
  },
];

export default function Currentwork() {
  return (
    <div className="Currentwork">
      <h4 className="text-center">Our Project</h4>
      <div className="innerDiv mt-3">
        {Presentwork.map((item, key) => (
          <div className="cardss shadow">
            <div className="workimg">
              {/* <video
                src={item.video}
                autoPlay
                muted
                loop
                controls
                playsInline
                className="video-style"
              ></video> */}
              {/* <video width="600" height={22} controls>
                <source src={item.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
              <video autoPlay controls muted loop src={video}></video>
            </div>

            <h6 className="mt-3">{item.title}</h6>
            <p>
              <b>Location</b> : {item.location}
            </p>
            <p>
              <b>Project Type</b> : {item.proType}
            </p>
            <p>
              <b>Unit</b> : {item.unit}
            </p>
            <p>
              <b>Delivery Date</b> : {item.dropdate}
            </p>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
