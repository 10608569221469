import { useState } from "react";
import styles from "./Services.module.css";
import servicesData from "../../Assets/Data/services";
import { Link, useNavigate } from "react-router-dom";
import "./Service.scss";

import secodnImage from "../../Assets/LatestImage/2ndPhoto.jpg";
import thirdPhoto from "../../Assets/LatestImage/3rdImage.jpg";

import img1 from "../../Assets/Images/Services/prewealth.avif";
import img2 from "../../Assets/Images/Services/Jointventure.avif";
import img3 from "../../Assets/Images/Services/2xcapital.avif";
import img4 from "../../Assets/Images/Services/rental.avif";
import img5 from "../../Assets/Images/Services/pasagoa.avif";
import img6 from "../../Assets/Images/Services/landparnar.jpg";

import icon1 from "../../Assets/Icon/1st.png";
import icon2 from "../../Assets/Icon/2nd.png";
import icon3 from "../../Assets/Icon/3rd.png";
import icon4 from "../../Assets/Icon/4th.png";
import icon5 from "../../Assets/Icon/5th.png";
import icon6 from "../../Assets/Icon/6th.png";

import plan from "../../Assets/Images/Home/wealth-plan.jpg";
import venture from "../../Assets/Images/Home/joint-venture.jpg";
import growth from "../../Assets/Images/Home/capital-climb.png";
import goa from "../../Assets/Images/Home/goa.jpg";
import partner from "../../Assets/Images/Home/partner.jpg";

const founderList = [
  {
    id: 0,
    name: "Preset Wealth Plan",
    designation: "",
    profileImage: plan,
    profileImage: icon1,
    about: [
      "Our real estate business introduces a short-term investment concept, designed to offer fixed returns within a 3-12 month timespan. Investors commit to a predefined project—whether land or finished properties like villas—where both the investment amount and profit margins are fixed in advance. With a secure mechanism in place, ",
      "this model ensures a clear and reliable opportunity for investors seeking ",
      "assured returns within a set timeframe.",
    ],
  },
  {
    id: 1,
    name: "Joint Venture",
    designation: "",
    profileImage: icon2,
    about: [
      "We are not involved in construction. Instead, we focus on buying and selling properties that have clear legal titles and are ready for sale, such as land, villas, or apartments.",
      "When we take on a project, we invest 20% of the total project cost upfront to show our confidence & commitment. From there, we manage everything—from identifying the right property to completing the deal and making sales. We always secure bank approvals for the project, ensuring it is financially sound.",
      "We trade the land or, alternatively, partner with a builder on a Joint Development Agreement. In this case, the developer handles all approvals and construction at their cost, and we share the finished property. This model typically delivers a minimum 2x revenue compared to the original land value, within a pre-defined timeframe.",
      "Additionally, we invite investors to join us, which allows us to expand our network to multiple states and countries. This wider reach helps drive sales and opens doors for future projects.",
      "estate market, with the added assurance that we are equally invested in the success of every project.",
    ],
  },
  {
    id: 2,
    name: "2x Capital Climb",
    designation: "",
    profileImage: icon3,
    about: [
      "Capital Climb - 2X: Secure Land Investments with Guaranteed Growth",
      "This model specializes in trading land with clear legal titles or partnering with builders for development projects under Joint Development Agreements. The focus is on acquiring land, which is either traded for profit or developed by a builder who manages all approvals and construction at their own cost. In return, the finished property is received by us in a pre-defined ratio, typically yielding a minimum of 2x the value of the land within a pre-defined period. Additionally, 20% of the initial project cost is contributed from the company’s side to demonstrate commitment to each venture.",
      "With Capital Climb - 2X, retail investors have the opportunity to invest securely. In this model, investments are used exclusively for acquiring premium land parcels, which are carefully selected for high growth potential. The team ensures that the land has clear titles and legal approvals, with bank support in place for each project.",
      "Investors enjoy an unconditional 2x return on investment within 5 years, secured against tangible land assets. The proven model of successful land trading and joint development minimizes risk, while investor participation helps expand the network across India and beyond, unlocking wider sales opportunities.",
      "This model is suitable for investors looking to diversify their portfolios or invest in a stable, high-growth real estate market, providing a secure path to double investments in just 5 years without any operational engagement. It capitalizes on Goa’s booming real estate market with confidence, ensuring all investments are safe and yield guaranteed returns. Business Opportunities on Floor Land ,Villa & Apartments",
    ],
  },
  {
    id: 3,
    name: "BRR(Best Residential Rentals)",
    designation: "",
    profileImage: icon4,
    about: [
      "Long-Term Rental Mandates for Exclusive Premium Properties This model focuses on offering long-term rental mandates for exclusive high-end villas and apartments sold to real estate investors, featuring distinguished X factors.",
      "With comprehensive management, market-driven pricing, and a commitment to tenant satisfaction, investors can maximize rental income and enjoy hassle-free property management. We never bind our existing customers or new one in a lengthy contract . We provide office cleaning services on a daily , weekly , fortnightly or once- off basis hire our office cleaning services in on a daily, weekly or one-off basis – no strings attached. And very big thanks to our15+ years of experience and high standard commercial-grade equipment, our teams clean your office to the highest standards. From grimy tiles to dusty windows, you can trust us and leave all your commercial cleaning needs to us to deliver comprehensive commercial cleaning service.",
      "Business Opportunities on Floor",
    ],
  },
  {
    name: "PaisaGoa.Com",
    designation: "",
    profileImage: icon5,
    about: ["Fund Management Services"],
  },
  {
    name: "GoaLand Partners",
    designation: "",
    profileImage: icon6,
    about: [
      "Our Land - Your Development",

      "Prospects : Builders to developer our land on revenue sharing basis",
    ],
  },
];

function ServiceCard({ serviceName, imgURL, Icon, link }) {
  const navigate = useNavigate();

  return (
    <div className={styles.servicardMain}>
      <Link
        to={link}
        className={styles.cardWrapper}
        style={{ backgroundImage: `url(${imgURL})`, contain: "fit" }}
        onClick={() => window.scrollTo(0, 0)} // Scroll to top when clicked
      >
        <Icon className={styles.serviceLogo} />
        <span className={styles.serviceName}>{serviceName}</span>
      </Link>

      <button
        onClick={() => {
          navigate(link);
          window.scrollTo(0, 0);
        }}
      >
        Learn More...
      </button>
    </div>
  );
}

function Services({ handleShowBusiness }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = window.innerWidth <= 768;
  const [showModal, setShowModal] = useState(false);
  const [showIndex, setShowIndex] = useState(-1);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.wrapper}>
      <div className="service-container">
        {founderList.map((item, index) => (
          <div key={index} className="inner-container">
            <div className="left-container">
              <div className="founder-image-container">
                <img
                  src={item.profileImage}
                  alt={`${item.name} image`}
                  className="founder-image"
                />
              </div>
            </div>

            <div className="right-container">
              <div className="primary-details">
                <h5 className="name">{item.name}</h5>
              </div>

              <div className="about-content">
                {item.about.map((aboutText, index) => (
                  <p key={index} className="about-paragraph">
                    {aboutText}
                  </p>
                ))}
              </div>

              {/* <div
                className="read-more-container"
                onClick={() => {
                  handleShowBusiness(true, founderList[item.id]);
                }}
              >
                <p className="read-more-text">Read more...</p>
              </div> */}
              {item.id >= 0 && (
                <div
                  className="read-more-container"
                  onClick={() => {
                    handleShowBusiness(true, founderList[item.id]);
                  }}
                >
                  <p className="read-more-text">Read more...</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* <div className="servicemain">
        <div className="box1">
          <div className="imgdiv">
            <img src={img5} alt="founder1" />
          </div>

          <div className="content-div">
            <h5>PaisaGoa.Com</h5>
            <p>Fund Management Services</p>
          </div>
        </div>

        <div className="box1">
          <div className="imgdiv">
            <img src={secodnImage} alt="founder1" />
          </div>

          <div className="content-div">
            <h5>GoaLand Partners</h5>
            <p>
              Our Land - Your Development <br />
              Prospects : Builders to developer our land on revenue sharing
              basis
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Services;
