import React from "react";
import "./Carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaRegUserCircle } from "react-icons/fa";

import img1 from "../../Assets/testimonial/man1.jpg";
import img2 from "../../Assets/testimonial/man2.jpg";
import img3 from "../../Assets/testimonial/man3.jpg";
import girls from "../../Assets/testimonial/girl2.jpg";
import user from "../../Assets/Images/user.jpg";

export const Carousel = () => {
  const PreviousBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div>
        <svg
          className={className}
          onClick={onClick}
          style={{ transform: "rotate(180deg)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="24"
          viewBox="0 0 15 24"
          fill="none"
        >
          <path
            d="M2 2L12 12L2 22"
            stroke="#52E6C2"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    );
  };

  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div>
        <svg
          className={className}
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="24"
          viewBox="0 0 15 24"
          fill="none"
        >
          <path
            d="M2 2L12 12L2 22"
            stroke="#52E6C2"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    );
  };

  var settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          // prevArrow: null,
          // nextArrow: null,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      client_name: "Christine Natalie",
      client_message: `Working with Goa Land Partners has been a seamless experience. Their commitment and transparency have helped us achieve significant returns on our investment.`,
      image: user,
    },
    {
      client_name: " Anuj Malhotra",
      client_message: ` As a builder, partnering with Goa Land Partners was a game-changer for my business. Their revenue-sharing model and legal support made the process straightforward, allowing me to focus on development. The team handled every aspect with precision, from securing land to managing approvals. It’s rare to find such a committed partner in real estate.`,
      image: user,
    },
    {
      client_name: " Priya Nair",
      client_message: `Goa Land Partners has redefined what I look for in an investment opportunity. Their approach is both innovative and secure, providing fixed returns within a set timeline. I was impressed by their focus on quality locations and legal transparency, which has brought me peace of mind and great returns. It’s a pleasure to work with such a knowledgeable and dedicated team.`,
      image: user,
    },

    {
      client_name: " Ravi Mehta, Investor",
      client_message: `"Investing with Goa Land Partners has been a seamless experience. The team’s professionalism, transparent approach, and commitment to maximizing returns exceeded my expectations. Their in-depth knowledge of Goa’s real estate market made me feel secure, and the results have been fantastic. Highly recommended for anyone looking for a reliable partner in property investment."  .`,
      image: user,
    },
  ];

  return (
    <div className="slider_carousel">
      <Slider {...settings}>
        {testimonials?.map((el, i) => {
          return (
            <div className="slider_item">
              <figure className="main-container">
                <div className="author">
                  <h5>{el?.client_name}</h5>

                  <figcaption>
                    <blockquote>{el?.client_message}</blockquote>
                    <div className="arrow"></div>
                  </figcaption>
                </div>

                <img src={el?.image} alt="sq-sample10" />
              </figure>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
