import styles from "./about.module.css";
import randomImg from "../../Assets/Images/Final_img/11.jpg";
import teamImg from "../../Assets/Images/Final_img/10.jpg";
import aboutCoverImg from "../../Assets/Images/Final_img/10.jpg";
// import videoOfLeft from "../../Assets/videoplayback (1).mp4";
import Teammember from "../../Components/Teammember/Teammember";
import { Services } from "../../Components";
import FAQ from "../../Components/FAQ/FAQ";
import AboutUs from "../../Components/AboutUs/AboutUs";
import siderIme from "../../Assets/LatestImage/sider.jpg";

const data = [
  {
    title:
      "What types of investment opportunities does Goa Land Partners offer?",
    description:
      "We offer a variety of investment models, including short-term investments with fixed returns, joint ventures through revenue-sharing agreements, and long-term rental mandates for premium properties. Our approach allows investors to choose from flexible, secure options based on their goals and preferences.",
  },
  {
    title: "How does the joint venture model work?",
    description:
      "In our joint venture model, we acquire land and partner with builders on a revenue-sharing basis. We contribute 20% of the project cost upfront, and the builder handles the construction and approvals. Upon completion, the finished property is shared in a pre-defined ratio, typically doubling the value of the land investment. This model minimizes risk for the investor while offering significant returns.",
  },
  {
    title: "Is my investment secure with Goa Land Partners?",
    description:
      "Absolutely. We prioritize transparency and legal compliance in all our projects. Each property we handle is bank-approved and comes with a clear legal title, ensuring that your investment is safeguarded. Our fixed-return and capital appreciation models are designed with investor security as a top priority.",
  },
  {
    title: "What is the Capital Climb - 2X model?",
    description:
      "The Capital Climb - 2X model is a secure land acquisition strategy where investors can expect a minimum of twice the return on investment within five years. By investing in high-growth land parcels with clear legal titles, this model provides stability and significant appreciation, ideal for investors seeking long-term growth.",
  },
  {
    title:
      "Who can invest with Goa Land Partners, and is there a minimum investment requirement?",
    description:
      "Our investment opportunities are open to all qualified investors, including individuals, corporations, and NRIs looking to diversify into Goa’s real estate market. The minimum investment requirement varies depending on the project and the model, allowing flexibility for investors of different scales to participate.",
  },
];

const About = () => {
  return (
    <>
      <div className={styles.wrapper}>
        {/* <h2 className="text-center fw-bold mt-5 mb-4">About Us</h2> */}
        <div
          className={styles.heading}
          style={{
            backgroundImage: `url(${siderIme})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            objectFit: "cover",
          }}
        >
          {" "}
          ABOUT US{" "}
        </div>

        {/* team member */}
        {/* <div className="teamCon mt-5">
          <h2 className="text-center">Meet Our Team</h2>

          <Teammember />
        </div> */}

        {/* <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <span className={styles.aboutHead}>
              {" "}
              A CLEANING COMPANY THAT YOU CAN TRUST FOR ALL YOUR CLEANING NEEDS
            </span>

            <span className={styles.aboutDesc}>
              At Best 1 Cleaners, we’re known across Australis for our top-notch
              Domestic and Commercial cleaning and maintenance services. Whether
              it’s Hospitality, Education, Fitness, Retail, or the medical
              sector, we’ve got you covered.
              <br />
              <br />
              Our team of cleaning professionals is the cream of the crop. We
              handpick each member after thorough training and background
              checks, ensuring they meet our high standards.
              <br />
              <br />
              Our goal is simple: provide world- class cleaning services while
              minimizing our impact on the environment. That’s why we use safe,
              eco-friendly products and supplies.
              <br />
              <br />
              With Best 1 Cleaners, you can trust that your space will be
              spotless and sustainable. We’re dedicated to delivering results
              that leave our clients satisfied, every time.
            </span>
          </div>

          <img src={randomImg} className={styles.aboutImg} alt="" />
        </div> */}

        {/* <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              {" "}
              Choose us and you will receive
            </span>

            <span className={styles.ourStoryDesc}>
              <ul>
                <li> Guaranteed customer satisfaction every time.</li>
                <li>
                  {" "}
                  Cleaning services that give you great value for your money.
                </li>
                <li>
                  {" "}
                  A team of talented and skilled cleaners ready to tackle any
                  job.
                </li>
                <li>
                  {" "}
                  Eco-friendly practices that reduce our environmental impact.
                </li>
              </ul>
            </span>

            <span>
              {" "}
              In addition to commercial cleaning, we also help our customers
              with recycling programs. We are committed to promoting green, safe
              and non-toxic cleaning methods and supplies. Our team prioritizes
              creating a clean, safe , and healthy workplace for our clients.
            </span>

            <span>
              {" "}
              If you want more details about our cleaning services or are
              interested in learning about our recycling programs, don’t
              hesitate to reach out to us. We’re here to help!
            </span>
          </div>

          <img src={teamImg} className={styles.ourStoryImg} alt="" />
        </div> */}

        {/* <Services /> */}

        {/* <div className={styles.aboutus}>
          <p>
            <b> Booming Tourism Hub:</b> <br /> Goa’s thriving tourism industry
            creates a consistent demand for rental properties, enhancing
            investment potential and providing steady rental income.
          </p>

          <p className="mt-2">
            <b> High Property Appreciation :</b> <br />
            Goa’s real estate market has shown significant appreciation in
            recent years, making it one of India’s most lucrative areas for real
            estate investment.
          </p>

          <div className="mt-2">
            <b>Exotic Location Appeal</b> <br />
            <p>
              Known for its beaches, cultural diversity, and laid-back
              lifestyle, Goa attracts a global audience, increasing property
              value and international interest.
            </p>
          </div>
          <div className="mt-2">
            <b>Pro-Business Climate</b> <br />
            <p>
              {" "}
              Goa’s government policies are investor-friendly, supporting
              development projects and encouraging investment in real estate
            </p>
          </div>
          <div className="mt-2">
            <b>High Demand for Luxury Real Estate</b> <br />
            <p>
              There is a growing demand for premium villas, apartments, and
              vacation rentals, presenting profitable opportunities for high-end
              property investments.
            </p>
          </div>
          <div className="mt-2">
            <b>Low Property Acquisition Costs</b> <br />
            <p>
              Compared to metro cities, Goa’s property prices are relatively
              lower, providing an accessible entry point for investors looking
              to tap into a growing market.
            </p>
          </div>
          <div className="mt-2">
            <b> Strong Rental Yields</b> <br />
            <p>
              {" "}
              With Goa’s year-round influx of tourists, rental yields are among
              the highest in India, making short- and long-term rental
              properties a profitable venture.
            </p>
          </div>
          <div className="mt-2">
            <b> Evolving Infrastructure</b> <br />
            <p>
              {" "}
              Ongoing infrastructure projects like the Mopa International
              Airport and enhanced road networks are transforming Goa into a
              more accessible and connected region, further boosting real estate
              value.
            </p>
          </div>
          <div className="mt-2">
            <b>Stable Real Estate Market</b> <br />
            <p>
              {" "}
              Goa’s market experiences steady growth with lower volatility,
              offering a secure and consistent return on investment compared to
              more fluctuating markets.
            </p>
          </div>
          <div className="mt-2">
            <b>Growing Investor Demand</b> <br />
            <p>
              {" "}
              Goa has become a popular choice among NRIs and domestic investors
              alike, resulting in a competitive market and higher resale
              potential for well-located properties.
            </p>
          </div>
          <div className="mt-2">
            <b></b>
            <p></p>
          </div>
          <div className="mt-2">
            <b></b>
            <p></p>
          </div>
          <div className="mt-2">
            <b></b>
            <p></p>
          </div>
        </div> */}

        <div className={`container ${styles.aboutus} my-5`}>
          <div className="row">
            <div className="col-12">
              <p>
                <strong>Booming Tourism Hub:</strong>
                <br />
                Goa’s thriving tourism industry creates a consistent demand for
                rental properties, enhancing investment potential and providing
                steady rental income.
              </p>
            </div>

            <div className="col-12 mt-2">
              <p>
                <strong>High Property Appreciation:</strong>
                <br />
                Goa’s real estate market has shown significant appreciation in
                recent years, making it one of India’s most lucrative areas for
                real estate investment.
              </p>
            </div>

            <div className="col-12 mt-2">
              <strong>Exotic Location Appeal</strong>
              <br />
              <p>
                Known for its beaches, cultural diversity, and laid-back
                lifestyle, Goa attracts a global audience, increasing property
                value and international interest.
              </p>
            </div>

            <div className="col-12 mt-2">
              <strong>Pro-Business Climate</strong>
              <br />
              <p>
                Goa’s government policies are investor-friendly, supporting
                development projects and encouraging investment in real estate.
              </p>
            </div>

            <div className="col-12 mt-2">
              <strong>High Demand for Luxury Real Estate</strong>
              <br />
              <p>
                There is a growing demand for premium villas, apartments, and
                vacation rentals, presenting profitable opportunities for
                high-end property investments.
              </p>
            </div>

            <div className="col-12 mt-2">
              <strong>Low Property Acquisition Costs</strong>
              <br />
              <p>
                Compared to metro cities, Goa’s property prices are relatively
                lower, providing an accessible entry point for investors looking
                to tap into a growing market.
              </p>
            </div>

            <div className="col-12 mt-2">
              <strong>Strong Rental Yields</strong>
              <br />
              <p>
                With Goa’s year-round influx of tourists, rental yields are
                among the highest in India, making short- and long-term rental
                properties a profitable venture.
              </p>
            </div>

            <div className="col-12 mt-2">
              <strong>Evolving Infrastructure</strong>
              <br />
              <p>
                Ongoing infrastructure projects like the Mopa International
                Airport and enhanced road networks are transforming Goa into a
                more accessible and connected region, further boosting real
                estate value.
              </p>
            </div>

            <div className="col-12 mt-2">
              <strong>Stable Real Estate Market</strong>
              <br />
              <p>
                Goa’s market experiences steady growth with lower volatility,
                offering a secure and consistent return on investment compared
                to more fluctuating markets.
              </p>
            </div>

            <div className="col-12 mt-2">
              <strong>Growing Investor Demand</strong>
              <br />
              <p>
                Goa has become a popular choice among NRIs and domestic
                investors alike, resulting in a competitive market and higher
                resale potential for well-located properties.
              </p>
            </div>
          </div>
        </div>

        <AboutUs className="founders-container" />

        <FAQ data={data} />
      </div>
    </>
  );
};

export default About;
