import styles from "./ContactUs.module.css";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { useEffect } from "react";
import FAQ from "../../Components/FAQ/FAQ";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SiGmail } from "react-icons/si";
import { FaPhone } from "react-icons/fa6";
//done
const data = [
  {
    title:
      "What types of investment opportunities does Goa Land Partners offer?",
    description:
      "We offer a variety of investment models, including short-term investments with fixed returns, joint ventures through revenue-sharing agreements, and long-term rental mandates for premium properties. Our approach allows investors to choose from flexible, secure options based on their goals and preferences.",
  },
  {
    title: "How does the joint venture model work?",
    description:
      "In our joint venture model, we acquire land and partner with builders on a revenue-sharing basis. We contribute 20% of the project cost upfront, and the builder handles the construction and approvals. Upon completion, the finished property is shared in a pre-defined ratio, typically doubling the value of the land investment. This model minimizes risk for the investor while offering significant returns.",
  },
  {
    title: "Is my investment secure with Goa Land Partners?",
    description:
      "Absolutely. We prioritize transparency and legal compliance in all our projects. Each property we handle is bank-approved and comes with a clear legal title, ensuring that your investment is safeguarded. Our fixed-return and capital appreciation models are designed with investor security as a top priority.",
  },
  {
    title: "What is the Capital Climb - 2X model?",
    description:
      "The Capital Climb - 2X model is a secure land acquisition strategy where investors can expect a minimum of twice the return on investment within five years. By investing in high-growth land parcels with clear legal titles, this model provides stability and significant appreciation, ideal for investors seeking long-term growth.",
  },
  {
    title:
      "Who can invest with Goa Land Partners, and is there a minimum investment requirement?",
    description:
      "Our investment opportunities are open to all qualified investors, including individuals, corporations, and NRIs looking to diversify into Goa’s real estate market. The minimum investment requirement varies depending on the project and the model, allowing flexibility for investors of different scales to participate.",
  },
];

function ContactUs() {
  const options = {
    publicKey: process.env.REACT_PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "best1_cleaners",
      // Allow 1 request per 10s
      throttle: 1000,
    },
  };

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    phoneNumber: "",
    fullName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormData({
      email: "",
      message: "",
      phoneNumber: "",
      fullName: "",
    });

    // Replace these with your actual email service, template ID, and user ID
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    console.log("serviceId: ", serviceId);
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    console.log("templateId: ", templateId);

    try {
      await emailjs.send(serviceId, templateId, formData, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      });

      // Clear form data after successful submission
      setFormData({
        email: "",
        phoneNumber: "",
        message: "",
        fullName: "",
      });

      toast.success("Thank you! Your message has been sent successfully.", {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      toast.success("Thank you! Your message has been sent successfully.", {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    emailjs.init(options);
  }, []);

  return (
    <div className={styles.contact_us}>
      <h2 className={`text-center ${styles.heading}`}>Contact Us</h2>

      <div className={styles.contactFormAndAddress}>
        <div className={styles.contact_locations}>
          <h4 className={styles.subheading}>Get In Touch</h4>

          <div className={styles.contactContainer}>
            <div className="d-flex gap-2">
              <a className={styles.linkcolor} href="tel:+919302055555">
                <FaPhone size={14} />

                <p>+91 9302055555</p>
              </a>
            </div>

            <div className="d-flex gap-2">
              <a
                className={styles.linkcolor}
                href="mailto:sunil@khubchandani.info"
              >
                <SiGmail size={20} />

                <p>sunil@khubchandani.info</p>
              </a>
            </div>
          </div>

          <div className={styles.socialLinks}>
            <h6>Social Media </h6>

            <div style={{ marginTop: "7px" }}>
              <p>
                {" "}
                <a
                  className="text-danger"
                  href="https://www.instagram.com/goarealtypartner/"
                  target="black"
                >
                  <FaInstagram size={30} />
                </a>{" "}
              </p>
              <p>
                <a
                  href="https://www.facebook.com/profile.php?id=61566991147065"
                  target="black"
                >
                  <FaFacebook size={30} />
                </a>{" "}
              </p>
            </div>
          </div>

          <div className={styles.locationContainer}>
            <h4>Location</h4>
            <p>
              La Capella, Villa-9 Pequeno chinvar Plot survey no 451/1-B &
              451/1-G, Village Anjuna, Bardez, North Goa.
            </p>
            <p>Goa - 403509</p>
          </div>

          <div className={styles.map}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d26021.117599011668!2d73.73152066214253!3d15.585288541653389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sLa%20Capella%2C%20Villa-9%20Pequeno%20chinvar%20Plot%20survey%20no%20451%2F1-B%20%26%20451%2F1-G%2C%20Village%20Anjuna%2C%20Bardez%2C%20North%20Goa.%20%20Goa%20-%20403509!5e1!3m2!1sen!2sin!4v1730102408112!5m2!1sen!2sin"
              width="90%"
              height="250px"
              margin="auto"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <form className={styles.contact_form} onSubmit={handleSubmit}>
          <h4 className={`text-center ${styles.subheading}`}>Send A Message</h4>

          <div className={styles.formField}>
            <div className={styles.label}>Full Name</div>
            <input
              className={styles.formInput}
              type="text"
              id="fullName"
              required
              name="fullName"
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}>Email</div>
            <input
              className={styles.formInput}
              type="email"
              onChange={handleInputChange}
              id="email"
              required
              name="email"
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}>Phone</div>
            <input
              className={styles.formInput}
              type="tel"
              required
              id="phoneNumber"
              onChange={handleInputChange}
              name="phoneNumber"
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}> Your Message </div>
            <textarea
              className={styles.formInput}
              id="message"
              name="message"
              onChange={handleInputChange}
              rows="4"
            />
          </div>

          <button className={styles.submitBtn} type="submit">
            Submit
          </button>
        </form>
      </div>
      {/* <div className={styles.faqContainer}> */}
      <FAQ data={data} />
      {/* </div> */}
    </div>
  );
}

export default ContactUs;
