import React from "react";
import styles from "./footer.module.css";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import LogoImage from "../../Assets/Images/glpwhitelogo.png";
import { SiGmail } from "react-icons/si";
import { FaPhone } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className={styles.sb_footer}>
      <div className={styles.sb_footer_link}>
        <div className={styles.sub_footer_div_link}>
          <h4>MENU</h4>

          <a href="/home">
            <p>Home</p>
          </a>
          <a href="/about">
            <p>About Us</p>
          </a>
          <a href="/Contact">
            <p>Contact Us</p>
          </a>

          <img src={LogoImage} className={styles.footerImg} alt="" />
        </div>

        <div className={styles.sub_footer_div_link}>
          <h4>Business Model</h4>

          {/* <a href="#"> */}
          <p>PreSet Wealth Plan</p>
          {/* </a> */}
          {/* <a href="#"> */}
          <p>Joint Venture</p>
          {/* </a> */}
          {/* <a href="#"> */}
          <p>2x Capital Climb</p>
          {/* </a> */}
          {/* <a href="#"> */}
          <p>Rental</p>
          {/* </a> */}
          {/* <a href="#"> */}
          <p>PaisaGoa.com</p>
          {/* </a> */}
          {/* <a href="#"> */}
          <p>Goa Land Partners</p>
          {/* </a> */}
        </div>

        <div className={styles.sub_footer_div_link}>
          <h4>CONTACT US</h4>

          <div className={styles.footer_sub_add}>
            <div className={styles.social}>
              <a href="mailto:sunil@khubchandani.info">
                <SiGmail size={14} />
                <p>sunil@khubchandani.info</p>
              </a>
            </div>

            <div className={styles.social}>
              <a href="tel:+919302055555">
                <FaPhone size={14} />
                <p>+91 9302055555</p>
              </a>
            </div>
          </div>

          <div className={styles.socialmedia}>
            <p>
              {" "}
              <a
                href="https://www.instagram.com/goarealtypartner/"
                target="black"
              >
                <FaInstagram size={20} />
              </a>{" "}
            </p>

            <p>
              <a
                href="https://www.facebook.com/profile.php?id=61566991147065"
                target="black"
              >
                <FaFacebook size={20} />
              </a>{" "}
            </p>
          </div>
        </div>
      </div>

      {/* <hr className={styles.hr} /> */}

      {/* <div className={styles.sb__footer_below}>
        <div className={styles.sb__footer_copyright}>
          <p>
            © {new Date().getFullYear()}{" "}
            <span style={{ fontWeight: "600" }}>Goa Reality Partners</span>. All
            rights reserved.
          </p>
        </div>

        <div className={styles.sb_footer_below_links}>
          <a href="">
            <div>
              <p>Term & Conditions</p>
            </div>
          </a>
          <a href="">
            <div>
              <p>Privacy</p>
            </div>
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
