import founder1 from "../Assets/Images/sunnel.jpg";
import founder2 from "../Assets/Images/jalaj.jpg";

export const founderList = [
  {
    name: "Sunil Khubchandani",
    designation: "Chief Executive Officer",
    profileImage: founder1,
    about:
      "Sunil Khubchandani brings over 30 years of rich experience in sales and strategic leadership across various industries, making him a driving force at Goa Land Partners. With a track record of success in building strong client relationships and driving revenue, Sunil has developed a vast network and deep ties within the business community, providing our team with invaluable connections and insights. His expertise in sales, combined with his visionary leadership, positions Goa Land Partners as a trusted and dynamic player in the market. As CEO, Sunil’s commitment to excellence and dedication to delivering results serve as the backbone of our mission to redefine real estate investments in Goa, ensuring growth and value for our clients and partners alike.",
  },
  {
    name: "Jalaj Tiwari",
    head:"(IIM Sambalpur)",
    designation: "Head of Marketing",
    profileImage: founder2,
    about:
      "Jalaj leads our marketing initiatives with expertise, strategic insight, and a deep understanding of the real estate landscape. A seasoned professional with an MBA from the prestigious Indian Institute of Management (IIM), Jalaj combines his knowledge of market dynamics with a creative approach to drive impactful campaigns and engage clients. His ability to analyze trends, craft compelling strategies, and adapt to the evolving real estate market makes him a valuable asset to Goa Land Partners. With a passion for delivering results and enhancing client experience, Jalaj is dedicated to building our brand’s presence and ensuring investor satisfaction at every step. ",
  },
];

export const perksList = [
  {
    perk: "Guaranteed customer satisfaction every time.",
  },
  {
    perk: "Cleaning services that give you great value for your money.",
  },
  {
    perk: "A team of talented and skilled cleaners ready to tackle any job.",
  },
  {
    perk: "Eco-friendly practices that reduce our environmental impact.",
  },
];
